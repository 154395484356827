<template>
  <div>
    <el-row>
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/index' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item>赛程管理</el-breadcrumb-item>
      </el-breadcrumb>
    </el-row>
    <el-row>
      <el-col :span="24">
        <el-steps :active="active" finish-status="success">
          <el-step title="步骤 1"></el-step>
          <el-step title="步骤 2"></el-step>
          <el-step title="步骤 3"></el-step>
        </el-steps>
        <div class="opeBox">
          <template v-if="this.active === 1">
            <el-input
              placeholder="请输入主场战队名"
              v-model="$store.state.team1_name"
            ></el-input>
            <el-input
              placeholder="请输入客场战队名"
              v-model="$store.state.team2_name"
            ></el-input>
          </template>
          <template v-else-if="this.active === 2">
            <el-date-picker
              v-model="startTime"
              type="datetime"
              placeholder="选择比赛开始日期"
            >
            </el-date-picker>
            <el-date-picker
              v-model="closeTime"
              type="datetime"
              placeholder="选择比赛结束日期"
            >
            </el-date-picker>
          </template>
          <el-button type="primary" style="margin-top: 12px" @click="next">{{
            msg
          }}</el-button>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { pushSchedule } from "@/api/schedule/index";
export default {
  name: "",
  data() {
    return {
      active: 1,
      startTime: "",
      closeTime: "",
      msg: "添加",
    };
  },
  methods: {
    next() {
      console.log(this.active);
      if (this.active === 1) {
        this.active++;
      } else if (this.active === 2) {
        this.active++;
        this.msg = "发布";
      } else {
        this.msg = "添加";
        this.setSchedule();
        setTimeout(() => {
          this.active === 1;
        }, 1000);
      }
    },
    setSchedule() {
      pushSchedule(this.$store.state.team1_name, this.$store.state.team2_name, this.startTime, this.closeTime)
        .then((res) => {
          this.$message.success(res.data);
        })
        .catch((err) => {
          this.$message.error("设置失败！");
          console.log(err)
        });
    }
  },
};
</script>

<style scoped lang="less">
.el-row {
  margin: 20px;
}
.el-input {
  width: 20%;
  margin: 10px;
}
</style>
